import Head from "next/head";
import Script from "next/script";

import "../globals.css";
import '../components/Content/Content.scss'
import '../components/Header/Header.scss'
import '../components/HeroBanner/HeroBanner.scss'
import '../components/AppBanner/AppBanner.scss'
import '../components/ChatBanner/ChatBanner.scss'
import '../components/Download/Download.scss'
import '../components/VideoBanner/VideoBanner.scss'
import '../components/Content/Content.scss'
import '../components/ReleaseNotes/ReleaseNotes.scss'
import '../components/PluginPage/PluginPage.scss';
import '../components/Menu/Menu.scss';
import '../components/ErrorComponent/ErrorComponent.scss';
import '../components/AccessOptions/AccessOptions.scss';
import '../components/WebonInfo/WebonInfo.scss';


import Header from "../components/Header/Header";
import {ThemeContextProvider} from "../contexts/useTheme";
import posthog from 'posthog-js'
import {PostHogProvider} from 'posthog-js/react'
import {useEffect} from 'react'
import {useRouter} from 'next/router'


// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug()
        }
    })
}

export default function App({Component, pageProps}) {
    const router = useRouter()

    useEffect(() => {
        // Track page views
        const handleRouteChange = () => posthog?.capture('$pageview')
        router.events.on('routeChangeComplete', handleRouteChange)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [])

    return (
        <>
            <Head>
                <link rel="shortcut icon" href={"./favicon.ico"}/>
                <Script
                    src={"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"}
                />
                <title>Nomo</title>
            </Head>
            <ThemeContextProvider>

                    <Header/>
                    <PostHogProvider client={posthog}>
                        <Component {...pageProps} />
                    </PostHogProvider>

            </ThemeContextProvider>
        </>
    );
}