import { useEffect, useState } from "react";

export function useWnPage() {
  const showWnPage = useFeatureFlag("WN_PAGE");
  return showWnPage;
}

async function fetchFeatureFlagsCached() {
  const cachedFlags = localStorage.getItem("featureFlags");
  if (cachedFlags) {
    fetchFeatureFlags(); // re-fetch in the background without awaiting the promise
    return JSON.parse(cachedFlags);
  }
  return await fetchFeatureFlags();
}

async function fetchFeatureFlags() {
  const baseUrl = "https://hog.zeniq.dev/";
  const projectID = "1";
  const featureFlagUrl = `${baseUrl}api/projects/${projectID}/feature_flags/`;
  const readApiKey = "phx_AQji25lmXKWGsgFUxqeZTWq2EUV4kMVZHUKz7Gks78j";
  const headers = {
    Authorization: `Bearer ${readApiKey}`,
  };
  const res = await fetch(featureFlagUrl, { headers });
  const data = await res.json();
  const allFlags = data.results;
  localStorage.setItem("featureFlags", JSON.stringify(allFlags));
  return allFlags;
}

// cached promise to avoid duplicate requests
let fetchFlagsPromise = null;

const useFeatureFlag = (flagKey) => {
  const [isFlagEnabled, setIsFlagEnabled] = useState(false);

  useEffect(() => {
    const updateFlags = async () => {
      try {
        if (!fetchFlagsPromise) {
          fetchFlagsPromise = fetchFeatureFlagsCached();
        }
        const flagData = await fetchFlagsPromise;
        const flagObject = flagData.find((flag) => flag.key === flagKey);
        setIsFlagEnabled(flagObject.active);
      } catch (e) {
        console.error(e);
      }
    };

    updateFlags();
  }, [flagKey]);

  return isFlagEnabled;
};
